.labelWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 6px;

}

.label {
  font-size: 17px;
  font-weight: 700;
  display: block;
}

.success {
  color: #09c962;
}

.error {
  color: #e55e5e;
}

.status {
  font-size: 16px;
  font-weight: 700;
}
