.page {
  padding: 50px 60px 50px 30px;
}

.pageTitle {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  color: black;
}

.loaderWrapper {
  display: flex;
  padding: 100px;
  justify-content: center;
  align-items: center;
  color: var(--color-grey-text);
}

.spacer {
  height: 50px;
}

.devider {
  width: 100%;
  height: 1px;
  background-color: var(--color-anti-flash);
}


.card {
  box-shadow: 0px 0px 10px #e0ecf6;
  border-radius: 12px;
}
.tag {
  border-radius: 8px;
font-size: 14px;
  padding: 3px 6px;
  font-weight: 500;
  display: inline-block;
}

.pending {
  
  composes: tag;
  color: #ECBF08;
  background-color: #FEFBE9;
}
.secondary {
  composes: tag;
  color: #FAA616;
  background-color: #EBEEFF;
}
.bast{
  color: #656565;
  composes: tag;

  background-color: #e8e8e8;
}
.rejected {
  composes: tag;
  color: #E55E5E;
  background-color: #F6D3D3;
}
.approved {
  composes: tag;
  color: #09C962;
  background-color: #D8FCE8;
}
.showingResults {
  color: var(--color-light-text);
  font-size: 12px;
}

.paging {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.thin {
  font-weight: 300;
  font-size: 11px;
}