.listItem {
    display: flex;
    align-items: center;
    color: var(--color-text);
    cursor: pointer;
    gap: 15px;
    color: grey;
    font-weight: 300;
    font-size: 14px;
    padding: 6px 0;
    padding-left: 50px;
    text-decoration: none;
}

.active {
    background-color: #eee;
    color: var(--color-primary);
    font-weight: 700;
    border-right: 2px var(--color-primary) solid;
}

.listWrapper {
    display: flex;
    flex-direction: column;
}