.icon {
  display: inline-block;
  width: 30px;
  height: 18px;
  background-size: contain;
  background-repeat: no-repeat;
  flex-shrink: 0;
}

.yellowCheck {
  composes: icon;
  background-image: url("../../public/icons/check.svg");
  width: 24px;
  height: 22px;
}

.logo {
  composes: icon;
  background-image: url("../../public/logo.svg");
  width: 55px;
  height: 18px;
}

.coloredLogo {
  composes: icon;
  background-image: url("../../public/icons/colored-logo.svg");
  width: 115px;
  height: 40px;
}

.blueTick {
  composes: icon;
  width: 80px;
  height: 80px;
  background-image: url("../../public/icons/blue-rounded-tick.svg");
}

.roundedAlert {
  composes: icon;
  width: 80px;
  height: 80px;
  background-image: url("../../public/icons/rounded-alert.svg");
}

.sandWatch {
  composes: icon;
  width: 85px;
  height: 85px;
  background-image: url("../../public/icons/sand-watch.svg");
}

.fileUpload {
  composes: icon;
  width: 35px;
  height: 44px;
  background-image: url("../../public/icons/file-upload.svg");
}
