.navbar {
  background-color: var(--color-primary);
  height: 56px;
  position: sticky;
  display: flex;
  padding-left: 60px;
  padding-right: 60px;
  top: 0;
  align-items: center;
  justify-content: space-between;
  z-index: 1201;
  color: white;
}

.welcomeText {
  color: white;
  font-size: 22px;
  font-weight: 300;
}

.merchantName {
  font-size: 18px;
  font-weight: 300;
}

.navPart {
  gap: 20px;
  display: flex;
  align-items: center;
}

.navLink {
  color: white;
  text-decoration: none;
}

.integrateWithDeema {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  font-size: 14px;
}
