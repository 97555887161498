.page{
    max-width: 438px;
}
.merchant {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}
.subInfo {
  font-size: 14px;
  font-weight: lighter;
  color: #2f2e41;
  display: flex;
  justify-content: space-between;

}
.sectionTitle {
  font-size: 18px;
  font-weight: 700;
  color: #202731;

}
.orderId{
    composes: sectionTitle;
    width: 100%;

    display: flex;
    justify-content: space-between;
}
.status{
    display: flex;
    gap: 5px;
}
.infoText{
    font-size: 16px;
    font-weight: 600;
    display: inline-block;
    margin-left: 4px;
}
.merchantInfo {
    padding-inline-start: 10px;
    flex: 1;

}
.orderInfo{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 10px;
    
    padding-bottom: 10px;
}
.clientInformation{
    display: flex;
    align-items: center;
   
    padding: 5px 0;
    justify-content: space-between;
}
.clientMainInfo{
    display: flex;
  align-items: center;
}
.clientName{
    font-size: 14px;
    font-weight: 600;
    color: #4D4F5C;
}
.clientNumber{
    font-size: 12px;
    color: #4D4F5C;
    font-weight: lighter;
}
.paymentInfo{
    padding-top: 10px;
    padding-bottom: 10px;
}
.spaceBetwenTitle{
    display: flex;
    justify-content: space-between;

}
.amounts{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 10px 0;

}
.amountCard{
    width: 100%;
    height: 74px;
    padding: 10px 12px;
    background-color: #F2F7FBCF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;



}
.amountTitle{
    display: flex;
    justify-content: space-between;


}
.amountTitle span{
    color: black;
    display: flex;
    gap: 10px;
}
.amountTitle span div{
border-radius: 14px;
border: 1px solid;
padding: 1px 10px;
}
.amountDate{
    font-size: 12px;
    font-weight: 699;
    color: #2F2E41;

}
.amountNumber{
    color: #222629;
    font-size: 14px;

}
.totalAmount{
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: bold;
    padding: 10px 0;
}
.refneded{
    composes: totalAmount;
    font-size: 16px;

}

.totalAmount p{
    color: black;

}
.refneded p{
    color:  #E55E5E;
    display: flex;
    align-items: center;
    grid-area: 10px;
 }
 .paymentDetails{
    composes: sectionTitle;
    margin: 10px 0;

 }