.modalBox {
  background-color: white;
  border-radius: 12px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px 70px;
  max-height: 85vh;
}

.DialogContent:focus {
  outline: none;
}

.close {
  height: 15px;
  width: 15px;
  color: var(--violet11);
  background-image: url('../../../public/icons/close.svg');
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  cursor: pointer;
  top: 15px;
  right: 15px;
}

.title {
  font-size: 24px;
  color: var(--color-primary);
  text-align: center;
}

.closeText {
  font-size: 14px;
  color: var(--color-primary);
  text-decoration: underline;
  text-align: center;
}

.closeText span {
  cursor: pointer;
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
