.filters {
  display: flex;
  gap: 10px;
}

.paging {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.showingResults {
  color: var(--color-light-text);
  font-size: 12px;
}

.thin {
  font-weight: 300;
  font-size: 11px;
}

.tag {
  border-radius: 8px;
  padding: 3px 6px;
  display: inline-block;
}

.cancelled {
  composes: tag;
  color: #e55f5e;
  background-color: #ffe4e2;
}

.captured {
  composes: tag;
  color: #09c962;
  background-color: #d8fce8;
}

.pending {
  composes: tag;
  color: #ecbf08;
  background-color: #fefbe9;
}

.onePayment {
  composes: tag;
  color: #7e2ef2;
  background-color: #f2eafe;
}

.theePayments {
  composes: tag;
  color: #FAA616;
  background-color: #ebeeff;
}
