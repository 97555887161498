.wrapper {
    border: 1px var(--color-light-text) solid;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 350px;
    height: 180px;
    color: #c1c5d8;
}

.greyFileIcon {
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    flex-shrink: 0;
    background-image: url("./file-upload-grey.svg");
    width: 20px;
    height: 24px;
    margin-bottom: 5px;
}

.cta {
    background-color: none;
    border: none;
    outline: none;
    text-decoration: underline;
    color: #00B5F1;
    cursor: pointer;
    font-size: 14px;
}

.title {
    font-size: 16px;
    font-weight: 700;
}

.or {
    font-size: 14px;
    font-weight: 700;
}