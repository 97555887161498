.filters {
  display: flex;
  gap: 10px;
}

.paging {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.showingResults {
  color: var(--color-light-text);
  font-size: 12px;
}

.thin {
  font-weight: 300;
  font-size: 11px;
}

.tag {
  border-radius: 8px;
  padding: 3px 6px;
  display: inline-block;
}

.green {
  composes: tag;
  color: #09c962;
  background-color: #d8fce8;
}

.yellow {
  composes: tag;
  color: #ecbf08;
  background-color: #fefbe9;
}


.purple {
  color: #7e2df2;
  font-size: 16px;
}

.red {
  color: #e55e5e;
  font-size: 16px;
}

.title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 8px;
}

.metaDataWrapper {
  padding-left: 20px;
}

ul {
  padding-left: 30px;
}

.collapsWrapper {
  padding-top: 10px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.thin {
  font-weight: 300;
  font-size: 11px;
}

.invoiceWrapper {
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.invoiceRow {
  display: flex;
  align-items: center;
}

.invoiceLabel {
  width: 200px;
}

.seperator {
  height: 1px;
  width: 100%;
  background-color: #aaa;
  margin-top: 2px;
  margin-bottom: 2px;
}
