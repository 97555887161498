.gradientCard {
  padding: 18px 25px;
  background: transparent
    linear-gradient(264deg, #7e2df2 0%, #4a61f4 51%, #00b6f1 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 10px;
  color: white;
}

.sectionTitle {
  color: #354052;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 12px;
}

.section {
  margin-top: 12px;
  margin-bottom: 12px;
}

.statsWrapper {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.statsCard {
  padding: 28px 23px 24px 23px;
  flex: 1;
  max-width: 230px;
}

.statsTitle {
  font-size: 14px;
  font-weight: 300;
}

.statsNumber {
  font-weight: 500;
  font-size: 32px;
  color: var(--color-empathize);
}

.totalUsers {
  color: var(--color-light-blue);
}

.statsCurrency {
  color: var(--color-light-text);
  font-size: 14px;
}
