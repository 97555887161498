.errorText {
  color: var(--color-danger-text);
  font-weight: 300;
}

.title {
  font-size: 28px;
  font-weight: 700;
}

.title2 {
  font-size: 20px;
  font-weight: 500;
}

.lightText {
  font-size: 14px;
  font-weight: 300;
}

.thick {
  font-weight: 700;
}

.small {
  font-size: 12px;
}

.textPrimary {
  color: var(--color-primary);
}

.regularText {
  color: black;
  font-weight: 300;
}
