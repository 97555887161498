.wrapper {
  display: flex;
  height: 100vh;
}

.logo {
  margin-bottom: 20px;
}

.error {
  color: red;
}

.loginBg {
  flex: 1;
  height: 100%;
  background-image: url("../../../public/images/login-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.loginText {
  width: 60%;
  margin-right: auto;
}

.loginSection {
  width: 500px;
}

.loginContent {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  width: 330px;
  gap: 20px;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.keepMeLogedin {
  display: flex;
  gap: 10;
  align-items: center;
  margin-top: 10px;
}

.forget-password {
  margin-left: auto;
  text-decoration: none;
  color: var(--color-empathize);
}

.backWrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: auto;
}

.otpInput {
  border-radius: 0.5rem;
  min-width: 3rem;
  height: 3rem;
  border: 1.5px solid var(--color-light-text);
}
