.wrapper {
    color: black;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 440px;
}

.fieldsWrapper {
    display: flex;
    gap: 10px;
    width: 100%;
}
