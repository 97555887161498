.button {
  padding: 12px 28px;
  border-radius: 3px;
  color: black;
  color: white;
  border: white;
  outline: none;
  cursor: pointer;
  box-sizing: border-box;
  text-decoration: none;
  display: flex;
  gap: 10px;
  align-items: center;
}

.buttonPrimary {
  composes: button;
  background-color: var(--color-primary);
  color: white;
  width: fit-content;
}

.lg {
  padding: 16px 28px;
  font-size: 16px;
}

.whiteBordered {
  border: 1px white solid;
}

.buttonSecondary {
  composes: button;
  background-color: var(--color-secondary);
  color: var(--color-primary);
}

.slightlyRounded {
border-radius: 10px;
}

.buttonRounded {
  border-radius: 30px;
}

.buttonPrimaryText {
  color: var(--color-primary);
  text-decoration: underline;
  cursor: pointer;
}

.buttonGreyText {
  color: var(--color-grey-text);
  text-decoration: underline;
  cursor: pointer;
}

.noClick {
  cursor: not-allowed;
}

.disabled {
  composes: noClick;
  background-color: #999;
}

.block {
  width: 100%;
  border-radius: 7px;
}

.centerText {
  margin-left: auto;
  margin-right: auto;
}

.buttonSm {
  padding-left: 16px;
  padding-right: 16px;
}

.pageButton {
  background-color: white;
  border-radius: 10px;
  border: 1px solid #C1C5D8;
  padding: 5px 10px;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.pageButtonSelected {
  border: 1px solid black;
}
