.wrapper {
    padding-top: 150px;
    height: 75vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--color-spanish-grey);
}

.mainIcon {
    margin-bottom: 40px;
}

.title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 30px;
}

.description {
    max-width: 580px;
    line-height: 1.7;
    font-weight: 300;
    font-size: 18px;
    text-align: center;
    margin-bottom: 30px;
}

.bottomLine {
    font-size: 20px;
    font-weight: 700;
}