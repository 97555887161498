
.tag {
  border-radius: 8px;
  padding: 3px 6px;
  display: inline-block;
}

.desActive {
  composes: tag;
  color: #e55f5e;
  background-color: #ffe4e2;
}

.active {
  composes: tag;
  color: #09c962;
  background-color: #d8fce8;
}

