.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px #c1c5d8 solid;
  padding: 6px 10px 15px 10px;
  border-radius: 10px;
  min-width: 350px;
}

.success {
  border-color: #09c962;
}

.error {
  border-color: #e55e5e;
}

.docInfo {
  display: flex;
  align-items: center;
  gap: 15px;
}

.metaData {
  display: flex;
  gap: 20px;
  color: #c1c5d8;
  margin-top: 2px;
}

.cancelIcon {
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  flex-shrink: 0;
  background-image: url("./cancel.svg");
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.pdfIcon {
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  flex-shrink: 0;
  background-image: url("./pdf-file.svg");
  width: 27px;
  height: 36px;
}

.controls {
  display: flex;
  gap: 3px;
  align-items: center;
}

.clickable {
  cursor: pointer;
}
