.firstLine {
    font-size: 18px;
    margin-bottom: 6px;
}

.title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
}

.instructions {
    display: flex;
    gap: 20px;
    align-items: center;
}

.ctaBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    width: 100%;
}

.fileUploadWrapper {
    display: flex;
    gap: 20px;
}

.fileUploadColumn {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: stretch;
    gap: 20px;
}