:root {
  --color-primary: #faa616;
  --color-primary-light: #faa616;
  --color-secondary: white;
  --color-grey-text: #727c8e;
  --color-danger-text: #ff3333;
  --color-white-smoke: #f8f8f8;
  --color-anti-flash: #f1f4f6;
  --color-text: #5e5f6e;
  --color-spanish-grey: #9599a1;
  --color-light-text: #c1c5d8;
  --color-light-blue: #449de2;
  --color-empathize: #11141a;

  /* Border radius */
  --border-radius: 14px;
}
.MuiTableCell-head{
  font-weight: bold !important;
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

p {
  line-height: 1.4;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
label {
  color: var(--color-text);
}

html,
body {
  max-width: 100vw;
  font-family: "Roboto", sans-serif;
  line-height: 1.6;
  color: var(--color-text);
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button {
  background-color: inherit;
  border: none;
}
.PhoneInputCountry {
  border: 1px solid #5e5f6e70;
  padding: 10px;
  border-radius: 4px;
}
